<template>
  <div class="products">
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Seats') }}</h2>
      </div>
      <img class="img-fluid seat-image" src="@/assets/restaurant-seating-plan.png">
      <div class="seat-list">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">{{ $t('Seat ID') }}</th>
              <th scope="col">{{ $t('Status') }}</th>
              <th scope="col">{{ $t('Setting') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>2</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>3</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>4</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>5</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>6</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>7</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>11</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>12</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>13</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>14</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>21</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>22</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>23</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>24</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>31</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>32</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>33</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>34</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>35</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>36</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>37</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>38</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>41</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>42</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>43</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>44</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>45</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>46</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>51</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>52</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>53</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>54</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>61V</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>62V</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>71</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>72</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>73</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>74</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>75</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>76</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>77</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
            <tr>
              <td>78</td>
              <td>{{ $t('Avaliable')}}</td>
              <td><a href="#" class="btn btn-primary">{{ $t('Edit')}}</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'Seats',
  mounted(){
  },
  data(){
    return {
      seats: [],
    }
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.seat-image{
  margin-left: auto;
  margin-right: auto;
  max-width: 30%;
  display: block;
}
.seat-list{
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
</style>
